/* @tailwind base;
@tailwind components;
@tailwind utilities; 
    

body{
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.max-w-lg.mx-auto.mt-47.p-3.border.rounded-md.shadow-lg {
  border-radius: 10px;
  margin-top: 26px;
}
.max-w-lg.mx-auto.mt-20.p-4.border.rounded-md.shadow-lg.shadow-sm.shadow-md {
  margin-top: 40px;
}
